/* General reset for section */
.brand-logo-section {
    width: 100%;
    background-color: #f9f9f9; 
    /* background-color: #474747;  */
    padding: 60px 20px;
    display: flex;
    justify-content: center;
}

.brand-logo-container {
    max-width: 1200px;
    width: 100%;
    text-align: center;
}

/* Heading and description styling */
.brand-logo-heading {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 30px;
}

.brand-logo-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 60px;
}

/* Grid styling for logos */
.brand-logo-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    align-items: center;
}

.brand-logo-item img {
    width: 100%;
    max-width: 150px;
    height: auto;
    object-fit: contain;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .brand-logo-grid {
        grid-template-columns: repeat(4, 1fr);
    }

    .brand-logo-section {
        padding: 0px 20px;
    }
}

@media (max-width: 768px) {
    .brand-logo-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .brand-logo-section {
        padding: 0px 20px;
    }
}

@media (max-width: 480px) {
    .brand-logo-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .brand-logo-heading {
        font-size: 1.5rem;
    }
    .brand-logo-description {
        font-size: 0.9rem;
    }

    .brand-logo-section {
        padding: 0px 20px;
    }
}

