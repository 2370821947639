/* Banner section */
.top-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    /* height: 575px;  */
    /* width: 100%; */
}

/* Image Styles */
.top-banner-image img {
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    width: 100%;
    /* height: 100%; */
    height: auto;
    object-fit: cover;
    /* Ensure the image covers the entire container without distortion */
    display: block;
    /* Prevents extra space below the image */
}




/* Responsive Adjustments */
@media (max-width: 1024px) {
    .top-banner {
        height: 450px;
    }
}


@media (max-width: 768px) {
    .top-banner {
        height: 325px;
    }
}

@media (max-width: 425px) {
    .top-banner {
        height: 290px;
    }
}

@media (max-width: 375px) {
    .top-banner {
        height: 275px;
    }
}