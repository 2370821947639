/* Contact Form Section */
.contact-form-section {
    width: 100%;
    background-color: #f9f9f9;
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-form-container {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Form Heading */
.form-heading {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
}

/* Form Styles */
.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

label {
    font-size: 14px;
    font-weight: 500;
    color: #555;
}

input {
    padding: 10px 12px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s ease;
}

/* Show red border only after validation */
input.was-validated:invalid {
    border-color: red;
}

input.was-validated:valid {
    border-color: green;
}

input.was-validated:invalid:focus {
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}

/* Add custom message after invalid field */
input.was-validated:invalid:focus::after {
    content: "This field is required";
    color: red;
    font-size: 12px;
    margin-top: 4px;
    display: block;
}

/* Focus Styles for Valid Input */

input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}


/* Row for Multiple Fields */
.form-row {
    display: flex;
    gap: 20px;
}

.form-row .form-group {
    flex: 1;
}

/* Submit Button */
.submit-btn {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.submit-btn:hover {
    background-color: #0056b3;
}


/* Success and Error Messages */
.message {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
}

.message.success {
    color: green;
}

.message.error {
    color: red;
}


/* Show red border only after validation */
input.is-invalid {
    border-color: red;
}

input.is-invalid:focus {
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}


/* Mobile Responsive */
@media (max-width: 768px) {
    .contact-form-container {
        padding: 10px;
    }
    .form-row {
        flex-direction: column;
        gap: 10px;
    }
}
