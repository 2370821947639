/* Footer Section Styling */
.footer-section {
    width: 100%;
    background-color: #272066;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    top: 0;
    z-index: 1000;
}

/* Footer Container */
.footer-container {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    text-align: center; /* Align text in the center */
    padding: 15px 0;
}

/* Footer Paragraph */
.footer-container p {
    color: #fff;
}


/* Responsive Styling */
@media screen and (max-width: 768px) {
    
}
