/* Navbar Section Styling */
.navbar-section {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    z-index: 1000;
}

/* Navbar Container */
.navbar-container {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

/* Logo */
.navbar-logo img {
    width: 120px; /* Adjust as needed */
    height: auto;
}

/* Navbar Menu */
.navbar-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 38px;
    transition: all 0.3s ease;
}

.navbar-menu.active {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    z-index: 999;
}

.navbar-menu li {
    /* position: relative; */
    /* padding: 10px 20px; */
    text-align: center;
}

.hamburger-icon {
    display: none; /* Hide by default */
}

.hamburger-icon svg {
    vertical-align: middle;
}


.navbar-menu a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    transition: color 0.3s;

}

.navbar-menu a:hover {
    color: #2b73d5;
}

/* Contact Section */
.navbar-contact {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.navbar-contact .phone-icon {
    width: 40px;
    height: 40px;
}

/* Contact Section inside Hamburger Menu (Mobile) */
.navbar-contact-mobile {
    display: none; /* Hidden by default */
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    gap: 10px;
    padding: 10px 0;
}

/* Responsive Styling */

@media (max-width: 1027px) {
    .navbar-container{
        width: 95%;
    }

    .navbar-menu {
        gap: 28px;
    }

    .navbar-menu a {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .navbar-menu {
        display: none;
        flex-direction: column;
    }

    .navbar-contact {
        display: none;
    }

    .navbar-contact-mobile {
        display: flex; /* Show in the hamburger menu */
    }

    .hamburger-icon {
        display: block; /* Show only on small screens */
    }

    .navbar-menu li {
        padding: 10px 20px;
    }
}